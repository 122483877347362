export default function* (urlArr, list, canvasScaleXY) {
  let width = list[0].mycanvas.width
  for (let i = 0; i < list.length; i++) {
    let pageData = yield list[i].mycanvas.toDataURL({
      multiplier: 4,
    })
    let data = {
      width: width / canvasScaleXY,
      url: pageData,
    }
    urlArr.push(data)
  }
}

// 使用任务进行分片  解决循环的时候页面无法进行渲染更新
export function timeSlice_(fnc, time = 25, cb = setTimeout) {
  if (
    fnc.constructor.name !== 'GeneratorFunction' &&
    fnc.constructor.name !== 'AsyncGeneratorFunction'
  )
    return fnc()
  return function (...args) {
    const fnc_ = fnc(...args)
    let data
    return new Promise(async function go(resolve, reject) {
      try {
        const start = performance.now()
        // while 满足条件继续执行
        do {
          data = await fnc_.next((await data) && data.value)
        } while (!data.done && performance.now() - start < time)
        if (data.done) return resolve(data.value)
        cb(() => go(resolve, reject))
      } catch (e) {
        reject(e)
      }
    })
  }
}
