/** 转换jpeg为bmp 实际也支持png但可能不再保留alpha通道 */
export class ConvertJpegToBmp {
  public image = new Image()
  public canvas = document.createElement('canvas')
  public ctx = this.canvas.getContext('2d')
  //转换图片为BMP格式
  public convertJpegToBmp(base64: string) {
    this.ctx?.clearRect(0, 0, 9999, 9999)
    return new Promise((resolve, reject) => {
      try {
        this.image.src = base64
        this.image.onload = () => {
          this.canvas.width = this.image.width
          this.canvas.height = this.image.height
          this.ctx?.drawImage(this.image, 0, 0)
          // 转换成BMP
          const imageData = this.ctx?.getImageData(
            0,
            0,
            this.canvas.width,
            this.canvas.height
          )
          const bmpData = this.getBmpData(imageData!)
          const bmpBlob = new Blob([bmpData], { type: 'image/bmp' })
          this.blobToBase64(bmpBlob).then((res) => {
            resolve(res)
          })
        }
      } catch (error) {
        reject(error)
      }
    })
  }
  // 转base64
  private blobToBase64(blob: Blob) {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = () => {
          resolve(reader.result)
        }
      } catch (error) {
        reject(error)
      }
    })
  }

  // 主要的转换方法
  private getBmpData(imageData: ImageData) {
    const width = imageData.width
    const height = imageData.height
    const rowSize = Math.floor((24 * width + 31) / 32) * 4 // 每行的字节数, 24位颜色深度
    const imageDataLen = rowSize * height
    const buffer = new ArrayBuffer(54 + imageDataLen)
    const dataView = new DataView(buffer)

    // BMP 文件头
    dataView.setUint8(0, 0x42) // B
    dataView.setUint8(1, 0x4d) // M
    dataView.setUint32(2, 54 + imageDataLen, true) // 文件大小
    dataView.setUint32(6, 0, true) // 保留字段
    dataView.setUint32(10, 54, true) // 数据偏移量

    // BMP 信息头
    dataView.setUint32(14, 40, true) // 信息头大小
    dataView.setUint32(18, width, true) // 图片宽度
    dataView.setUint32(22, height, true) // 图片高度，正值表示从下到上
    dataView.setUint16(26, 1, true) // 颜色平面数
    dataView.setUint16(28, 24, true) // 每个像素的位数，从32改为24
    dataView.setUint32(30, 0, true) // 无压缩
    dataView.setUint32(34, imageDataLen, true) // 图像数据长度
    dataView.setUint32(38, 0, true) // 水平分辨率
    dataView.setUint32(42, 0, true) // 垂直分辨率
    dataView.setUint32(46, 0, true) // 使用颜色索引
    dataView.setUint32(50, 0, true) // 总要的颜色索引

    // BMP 像素数据
    const pixelData = imageData.data
    let offset = 54

    for (let y = height - 1; y >= 0; y--) {
      for (let x = 0; x < width; x++) {
        const i = (y * width + x) * 4
        const r = pixelData[i]
        const g = pixelData[i + 1]
        const b = pixelData[i + 2]
        dataView.setUint8(offset++, b)
        dataView.setUint8(offset++, g)
        dataView.setUint8(offset++, r)
      }
      offset += rowSize - width * 3 // 行末填充字节数
    }

    return new Uint8Array(buffer)
  }
}

// 导出实例，基本使用此转换实例即可
export const convertJpegToBmp = new ConvertJpegToBmp()
