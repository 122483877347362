import * as IJS from '@/util/image-js'

/**
 *
 * @param {*} base64Image 你的Base64编码图像字符串
 */
export async function exportBmp1Bit(base64Image) {
  let image = await IJS.Image.load(base64Image)
  image = image.grey()

  let op = { threshold: 0.8 }
  image = image.mask(op)

  let imgURL = await image.toDataURL('image/bmp')

  return imgURL
}
