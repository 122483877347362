const addressTop = 281
const orderTop = 295
const materialTop = 309
const sizeTop = 324
const fixedLeft = 343
const dataSourceLeft4 = 389 // 4个字
const dataSourceLeft3 = 380 // 3个字
// const qrCodeTop = 370
const qrCodeTop = 340
const ncNameTop = 407
const graphLeft = 501.2222222222223
const graphTop = 280
const plantIndexLeft = 510
const plantIndexTop = 383
const lableIndexLeft = 515
const lableIndexTop = 400

const base = {
  fontSize: 10,
  fontPt: 8,
  lineHeight: 1,
  fontWeight: '',
  textAlign: 'left',
  splitByGrapheme: false,
}

// 3个字的基本信息
const fixedBaseMsg3 = {
  ...base,
  width: 40,
  height: 11.3,
}
// 4个字的基本信息
const fixedBaseMsg4 = {
  ...base,
  width: 54,
  height: 11,
}

export const bigPlankTemp = {
  type: 'plank',
  rectInfo: {
    left: 336.6,
    top: 274.4,
  },
  tag_height: 40,
  tag_width: 60, // 使用的时候要根据 this.cur_template 重新设置tag_width和tag_height
  tem_data: [
    {
      type: 'FixedText',
      data: {
        text: '项目地址：',
        left: fixedLeft,
        top: addressTop,
        ...fixedBaseMsg4,
      },
    },
    {
      type: 'FixedText',
      data: {
        text: '订单号：',
        left: fixedLeft,
        top: orderTop,
        ...fixedBaseMsg3,
      },
    },
    {
      type: 'FixedText',
      data: {
        text: '大板材质：',
        left: fixedLeft,
        top: materialTop,
        ...fixedBaseMsg4,
      },
    },
    {
      type: 'FixedText',
      data: {
        text: '大板尺寸：',
        left: fixedLeft,
        top: sizeTop,
        ...fixedBaseMsg4,
      },
    },
    {
      type: 'DataSource', // 项目地址value
      data: {
        left: dataSourceLeft4,
        top: addressTop,
        source_data: 'programAddress',
        ...base,
      },
    },
    {
      type: 'DataSource', // 订单号value
      data: {
        left: dataSourceLeft3,
        top: orderTop,
        source_data: 'orderNo',
        ...base,
      },
    },
    {
      type: 'DataSource', // 材质value
      data: {
        left: dataSourceLeft4,
        top: materialTop,
        source_data: 'material',
        ...base,
      },
    },
    {
      type: 'DataSource', // 尺寸value
      data: {
        left: dataSourceLeft4,
        top: sizeTop,
        source_data: 'size',
        ...base,
      },
    },
    {
      type: 'DataSource', // nc文件名
      data: {
        left: fixedLeft,
        top: ncNameTop,
        source_data: 'ncName',
        ...base,
      },
    },
    {
      type: 'DataSource', // 第几张大板
      data: {
        left: plantIndexLeft,
        top: plantIndexTop,
        source_data: 'plank_index',
        ...base,
        fontWeight: 'bold',
      },
    },
    {
      type: 'DataSource', // 第几页
      data: {
        left: lableIndexLeft,
        top: lableIndexTop,
        source_data: 'lable_index',
        ...base,
        fontWeight: 'bold',
      },
    },
    {
      type: 'QRcode', // 二维码
      data: {
        left: fixedLeft,
        top: qrCodeTop,
        source_data: 'qrCode',
        mHeight: 8 * 2,
        mWidth: 8 * 2,
      },
    },
    {
      type: 'Typography',
      data: {
        left: graphLeft + 10,
        top: graphTop,
        mHeight: 25,
        mWidth: 15,
        height: 94.49,
        width: 56.69,
        textItems: [
          {
            fontPt: 8,
            fontSize: 8,
            fontWeight: '',
            left: 0,
            positionName: 'LE',
            top: 47.245,
          },
          {
            fontPt: 8,
            fontSize: 8,
            fontWeight: '',
            left: 28.345,
            positionName: 'BE',
            top: 94.49,
          },
          {
            fontPt: 8,
            fontSize: 8,
            fontWeight: '',
            left: 56.69,
            positionName: 'RE',
            top: 47.245,
          },
          {
            fontPt: 8,
            fontSize: 8,
            fontWeight: '',
            left: 28.345,
            positionName: 'TE',
            top: 0,
          },
        ],
      },
    },
  ],
}
